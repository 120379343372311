html, body {
    font: 'Open Sans', sans-serif;
}

.form-control {
    width: 100%;
    height: 50px;
    border: solid 1px #ced8e6;
    color: #a2a8b0;
    font-weight: 300;
    padding: 0 20px;
}

.form-control-round {
  border-radius: 30px;
}

/*a, a:focus {
    color: #ff7900 !important;
    outline: none !important;
    text-decoration: none;
}*/

a:hover {
  text-decoration: underline;
}

.text-primary {
  color: #ff7900 !important;
}

.btn {
    border-width: 2px;
    font-weight: 300;
}

.btn-round {
    border-radius: 35px;
}

.btn-lg {
    height: 50px;
}

.btn-primary, .btn-primary:disabled {
    background-color: #ff7900;
    border-color: #ff7900;
    color: #fff !important;
}

.btn-primary:hover {
    background-color: #ffffff;
    border-color: #ff7900;
    color: #ff7900 !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #929496;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #929496;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #929496;
}

.fade.in {
  opacity: 1;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.5) !important;
}

footer .mat-icon:hover svg .cls-1 {
  fill: #ff7900;
}
