@import 'theme.scss';

.light-theme app-header .dropdown-content a{
  color: #000 !important;
}

.light-theme app-header .dropdown-content{
  background-color: mix($color-light-back-primary, $color-light-secondary, 94.5%); /*‭15856113‬/‭16777215‬ #f1f1f1*/
}

.light-theme app-header .dropdown-content {
  background-color: mix($color-light-back-primary, $color-light-secondary, 94.5%); /*‭15856113‬/‭16777215‬ #f1f1f1*/
  color: #000 !important;
}

.light-theme app-header a.active {
    color: #3a290d !important;
}

.light-theme app-header .background-grey {
    background: #343433;
    color: #fff;
}

.light-theme app-menu-stats .txt-bold {
  color: #000;
}

.light-theme app-home .mat-select-value-text,
.light-theme home-coin-landing .yellow-button,
.light-theme home-coin-landing .mat-select-value-text {
  color: white;
  font-weight: bold;
}

.light-theme .background-grey {
  background: mix($color-light-back-primary, $color-light-secondary, 92.5%);/*‭‭15527666‬‬/‭16777215‬ #eceef2*/
}

.light-theme #market-main-table .mat-tab-label {
    color: $color-light-secondary;
}

.light-theme .mat-row:nth-child(even) {
    background-color: $color-light-back-primary;
}

.light-theme .mat-row:nth-child(odd) {
    background-color: mix($color-light-back-primary, $color-light-secondary, 96%); /*‭‭16119544‬‬/‭16777215‬ #F5F6F8*/
}

.light-theme transaction-history .mat-row:nth-child(odd) {
    background-color: $color-light-back-primary;
}

.light-theme transaction-history .mat-row:nth-child(4n+2),
.light-theme transaction-history .mat-row:nth-child(4n+3) {
    background-color: mix($color-light-back-primary, $color-light-secondary, 96%); /*‭‭16119544‬‬/‭16777215‬ #F5F6F8*/
}

.light-theme app-header .dropdown-content a:hover {
    background-color: mix($color-light-back-primary, $color-light-secondary, 96.6%); /*‭14540253‬/‭16777215‬ #dddddd*/
}

.light-theme app-header .dropdown-background {
  background-color: $color-light-back-primary;
}

.light-theme app-header {
  background-color: #f16f21;
  color: #fff;
}

.light-theme app-header .navbar-light .navbar-toggler {
  color: rgba($color-dark-secondary, .5);
  border-color: rgba($color-dark-secondary, .5);
}

.light-theme app-header .navbar-light .navbar-toggler-icon {
  border-color: #fff;
}

.light-theme app-footer {
    background-color: $color-light-back-primary; /*‭10066329‬/‭16777215‬ #999999*/
}

.light-theme app-footer a {
  color: #000;
}

.light-theme app-balance-table .mat-row:hover {
    background-color: mix($color-light-back-primary, $color-light-secondary, 85%); /*‭14277081‬/‭16777215‬ #d9d9d9*/
}

.light-theme .announcement-unread {
    background-color: mix($color-light-back-primary, $color-light-secondary, 82.7%); /*‭13882323‬/‭16777215‬ #d3d3d3*/
}

.light-theme {
    & app-header,
    & app-about,
    & app-home,
    & app-stablecoin,
    & app-earn {
        & .foreground-stroke {
            stroke: #fff;
        }
    }
}

.light-theme .bg-grey {
    background-color: #eceef2;
}

.light-theme .foreground-stroke {
    stroke: #000;
}

.light-theme .background-stroke {
    stroke: $color-light-back-primary;
}

.light-theme .foreground-fill {
    fill: $color-light-secondary;
}

.light-theme .background-fill {
    fill: $color-light-back-primary;
}

.light-theme .chat.expanded .message {
    background-color: mix($color-light-back-primary, $color-light-secondary, 93.3%); /*‭15659252‬/‭16777215‬ #eef0f4*/
}

.light-theme .chat.expanded .chat-display {
    background-color: $color-light-back-primary;
}

.light-theme app-contact-support .chat.expanded .chat-input,
.light-theme app-chat .chat.expanded .chat-input {
    background-color: $color-light-back-primary;
}

.light-theme .main-chat .full-screen .message {
    background-color: mix($color-light-back-primary, $color-light-secondary, 93.3%); /*‭15659252‬/‭16777215‬ #eef0f4*/
}

.light-theme .main-chat .chat-input mat-icon,
.light-theme .main-chat .chat-username mat-icon {
    background-color: $color-light-back-primary;
}

.light-theme main.main_ {
    background-color: mix($color-light-back-primary, $color-light-secondary, 93.3%); /*‭15659252‬/‭16777215‬ #eef0f4*/
}

.light-theme main.main_about, .light-theme main.main_czar, .light-theme main.main_earn {
    background-color: #fff;
}

.light-theme app-home .trade-section {
    background-color: $color-light-back-primary;
}

.light-theme app-home .mat-row:hover {
    background-color: $color-light-back-primary;
}

.light-theme app-home .show-more-click:before {
    box-shadow: 10px 0px 0 $color-light-back-primary;
}

.light-theme app-home .show-more-click:after {
    box-shadow: -10px 0px 0 $color-light-back-primary;
}
/*‭‭15725044‬‬/‭16777215‬ #eff1f4*/
/*‭15659252‬/‭16777215‬ #eef0f4*/
.light-theme app-home .background-shade {
    background-image: linear-gradient(rgba(mix($color-light-back-primary, $color-light-secondary, 93.7%), 0), rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 0.2), rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 0.4), rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 0.6), rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 0.8), rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 1));
}

.light-theme app-home .background-shade-less {
    background-image: linear-gradient(rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 0), rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 0.2), rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 0.4), rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 0.6), rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 0.8), rgba(mix($color-light-back-primary, $color-light-secondary, 93.3%), 1));
}

.light-theme input:-webkit-autofill,
.light-theme textarea:-webkit-autofill,
.light-theme select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $color-light-back-primary inset;
    -webkit-text-fill-color: $color-light-secondary;
}

.light-theme app-trade-summary .summary-holder {
    background-color: mix($color-light-back-primary, $color-light-secondary, 96%);
    color: $color-light-secondary;
}

.light-theme .recaptcha-overlay {
    background-color: $color-light-back-primary;
}

.light-theme .chat {
    background-color: $color-light-back-primary;
}

.light-theme .chat {
    background-color: $color-light-back-primary;
    color: $color-light-secondary;
}

.light-theme .chat.expanded .chat-username {
    background-color: $color-light-back-primary;
}

.light-theme .emoji-panel {
    background-color: $color-light-back-primary;
}

.light-theme .mat-expansion-panel-header[aria-disabled=true] {
    color: $color-light-secondary !important;
}

.light-theme app-not-found .wrapper ,
.light-theme app-generic-response .wrapper {
    background-color: $color-light-back-primary;
}

.light-theme app-home .trade-section mat-card {
    box-shadow: 2px 2px 2px 0 rgba($color-light-secondary,.07), -2px -2px 2px 0 rgba($color-light-secondary,.07), 2px -2px 2px 0 rgba($color-light-secondary,.07), -2px 2px 2px 0 rgba($color-light-secondary,.07);
}

.light-theme app-home .featured {
    background-color: $color-light-back-primary;
}

.light-theme app-home .featured .coin-hills span {
    color: $color-light-secondary;
}

/***/
.light-theme main {
    background-color: mix($color-light-back-primary, $color-light-secondary, 93.3%); /*‭15659252‬/‭16777215‬ #eef0f4*/
}

.light-theme app-header,
.light-theme app-header a {
  color: #000 !important;
}

.light-theme main.main_coinlanding {
  background-color: #fff;
}

.light-theme .row.back {
    background-color: mix($color-light-back-primary, $color-light-secondary, 93.3%); /*‭15659252‬/‭16777215‬ #eef0f4*/
}

.light-theme .mat-elevation-z1,
.light-theme .mat-elevation-z2 {
    background-color: $color-light-back-primary;
}

.light-theme a {
    color: $color-light-primary;
}

.light-theme app-header a[mat-raised-button][color=primary],
.light-theme app-header a[mat-raised-button][color=primary],
.light-theme app-header a[mat-raised-button][color=primary]:focus,
.light-theme app-header button[mat-raised-button][color=primary],
.light-theme app-header button[mat-button][color=primary],
.light-theme .mat-step-header .mat-step-icon {
    color: $color-light-back-primary;
    text-decoration: none;
}

.light-theme a[mat-raised-button][color=primary],
.light-theme a[mat-raised-button][color=primary],
.light-theme a[mat-raised-button][color=primary]:focus,
.light-theme button[mat-raised-button][color=primary],
.light-theme button[mat-button][color=primary] {
    background-color: $color-light-primary;
    color: $color-light-back-primary;
    text-decoration: none;
}

.light-theme .mat-step-header .mat-step-icon,
.light-theme .mat-step-header .mat-step-icon-state-edit {
    border: 3px solid $color-light-primary;
    background-color: $color-light-back-primary;
    color: $color-light-back-primary;
    text-decoration: none;
}

.mat-step-icon-selected {
    background-color: $color-light-primary !important;
}

.light-theme .mat-flat-button.mat-primary[disabled],
.light-theme .mat-flat-button.mat-accent[disabled],
.light-theme .mat-flat-button.mat-warn[disabled],
.light-theme .mat-flat-button[disabled][disabled],
.light-theme .mat-raised-button.mat-primary[disabled],
.light-theme .mat-raised-button.mat-accent[disabled],
.light-theme .mat-raised-button.mat-warn[disabled],
.light-theme .mat-raised-button[disabled][disabled],
.light-theme .mat-fab.mat-primary[disabled],
.light-theme .mat-fab.mat-accent[disabled],
.light-theme .mat-fab.mat-warn[disabled],
.light-theme .mat-fab[disabled][disabled],
.light-theme .mat-mini-fab.mat-primary[disabled],
.light-theme .mat-mini-fab.mat-accent[disabled],
.light-theme .mat-mini-fab.mat-warn[disabled],
.light-theme .mat-mini-fab[disabled][disabled] {
    background-color: rgba($color-light-secondary, 0.12) !important;
    color: rgba($color-light-secondary, 0.26) !important;
}

.light-theme .border-box {
    border: 1px solid $color-light-primary;
    padding: 20px 20px 20px 20px;
    max-width: 600px
}

.light-theme .autotrade-border-box {
  border: 1px solid $color-light-primary;
  padding: 10px;
  font-size: 14px;
}

.light-theme mat-datepicker-toggle[color=primary],
.light-theme mat-icon[color=primary] {
    color: $color-light-primary;
    cursor: pointer;
}

.light-theme mat-icon[color=good] {
    color: #69cc66;
    cursor: pointer;
}

.light-theme a[mat-raised-button][color=accent],
.light-theme a[mat-raised-button][color=accent],
.light-theme a[mat-raised-button][color=accent]:focus,
.light-theme button[mat-raised-button][color=accent],
.light-theme button[mat-button][color=accent] {
    background-color: mix($color-light-back-primary, $color-light-secondary, 60%); /*/‭16777215‬ #999999*/
    color: $color-light-back-primary;
    text-decoration: none;
}

.light-theme ngx-mat-drp[color=accent] .ngx-mat-drp-date-input {
    color: mix($color-light-back-primary, $color-light-secondary, 60%) !important; /*/‭16777215‬ #999999*/
    text-decoration: none;
}

.light-theme a.mat-menu-item {
    text-decoration: none;
}

.light-theme mat-progress-bar .mat-progress-bar-primary::after,
.light-theme mat-progress-bar .mat-progress-bar-secondary::after {
    background-color: $color-light-primary;
}

.light-theme mat-progress-bar .mat-progress-bar-buffer {
    background-color: #ffcfa4;
}

.light-theme .kycmessage {
    color: $color-light-secondary;
}


.light-theme .pop-up-title {
    border-bottom: 1px solid #e2e2e2;
}

.light-theme .hover-pop-up {
    color: $color-light-secondary;
}

.light-theme .center-btn-icon {
    color: $color-light-primary;
}

.light-theme a.link {
    color: $color-light-primary !important;
}

.light-theme .available-balance-box {
    border: 1px solid $color-light-primary;
}

.light-theme #chatWidget,
.light-theme #formWidget {
    background-color: $color-light-primary;
}

.light-theme .message-icon:hover {
    color: $color-light-primary;
}

.light-theme .emoticon-icon:hover {
    color: $color-light-primary;
}

.light-theme .user {
    border: $color-light-primary 1px solid;
}

.light-theme .message-card .user {
    color: $color-light-primary;
}

.light-theme .api {
    color: $color-light-primary;
}

.light-theme .highlight {
    background-color: rgba($color-light-primary, 0.8) !important;
}

.light-theme  .info-box {
    border: 1px solid $color-light-primary;
}

/*.light-theme .clickable {
    color: $color-light-primary;
}*/

.light-theme .mat-column-sell-total,
.mat-column-sell-total-amount {
    color: $color-light-primary;
}

.light-theme .mat-footer-cell {
    color: $color-light-primary;
}

.light-theme .note-box {
    color: $color-light-primary;
}

.light-theme mat-row.active {
    background-color: $color-light-primary !important;
}

.light-theme .media-body h5 {
    color: $color-light-primary;
}

/*.light-theme thead tr {
    background: $color-light-primary;
}*/

.light-theme .lvl2-notify {
    border: $color-light-primary solid 1px;
}

.light-theme .volume-info {
    background-color: $color-light-primary;
}

.light-theme app-coin-balance .summary-total .min-w-limit {
    color: #000 !important;
}

.light-theme .txt-orange {
    color: $color-light-primary !important;
}

.light-theme .order-types-group .mat-tab-label-active {
    background-color: $color-light-back-primary !important;
    opacity: 1 !important;
}

.light-theme .order-types-group .mat-tab-label-container {
    background-color: $color-light-back-primary !important;
}

.light-theme .border-2 {
    border: 2px solid #ef6c00;
    border-color: #ef6c00 !important;
}

.light-theme .border-2-grey {
    border: 2px solid #808080;
}

.light-theme .main_security, .light-theme .main_bountyprogram{
  background-color: #fff;
}

@media (min-width: 1200px) {
  .light-theme app-header .dropdown-background {
    background-color: inherit;
  }

  .light-theme app-header,
  .light-theme app-header a {
    color: #fff !important;
  }
}

.light-theme .mat-elevation-z4
.light-theme .mat-table,
.light-theme .mat-paginator {
    background-color: $color-light-back-primary !important;
}
