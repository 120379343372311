$FontPathOpenSans: "./open-sans" !default;

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: normal;
  src: url('#{$FontPathOpenSans}/Regular/OpenSans-Regular.woff2') format('woff2'), url('#{$FontPathOpenSans}/Regular/OpenSans-Regular.woff') format('woff'), url('#{$FontPathOpenSans}/Regular/OpenSans-Regular.ttf') format('truetype'), url('#{$FontPathOpenSans}/Regular/OpenSans-Regular.svg#OpenSansRegular') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: normal;
  font-style: italic;
  src: url('#{$FontPathOpenSans}/Italic/OpenSans-Italic.woff2') format('woff2'), url('#{$FontPathOpenSans}/Italic/OpenSans-Italic.woff') format('woff'), url('#{$FontPathOpenSans}/Italic/OpenSans-Italic.ttf') format('truetype'), url('#{$FontPathOpenSans}/Italic/OpenSans-Italic.svg#OpenSansItalic') format('svg');
}
