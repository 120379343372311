@import 'theme.scss';

body.dark-theme,
body .dark-theme {
    background-color: $color-dark-back-secondary;
    color: $color-dark-secondary;
}

.dark-theme table.fees {
    color: #fff;
}

.dark-theme app-header a.active {
  color: #ffe8ca !important;
}

.dark-theme app-header {
  background-color: #f16f21;
  color: #fff !important;
}

.dark-theme app-header a {
  color: #fff !important;
}

.dark-theme app-header .background-grey {
    background: #2a2a2a;
}

.dark-theme app-menu-stats app-menu-stats .txt-bold {
  color: #fff;
}

.dark-theme .background-grey {
  background: $color-dark-back-primary;
}

.dark-theme .mat-option {
  color: #fff !important;
}

.dark-theme #market-main-table .mat-tab-label {
    color: $color-dark-secondary;
}

.dark-theme .mat-row:nth-child(even) {
    background-color: mix($color-dark-back-primary, $color-dark-secondary, 83.5%);
}

.dark-theme .mat-row:nth-child(odd) {
    background-color: $color-dark-back-secondary;
}

.dark-theme transaction-history .mat-row:nth-child(odd) {
    background-color: mix($color-dark-back-primary, $color-dark-secondary, 83.5%);
}

.dark-theme transaction-history .mat-row:nth-child(4n+2),
.dark-theme transaction-history .mat-row:nth-child(4n+3) {
    background-color: $color-dark-back-secondary;
}

.dark-theme app-header .dropdown-content a {
  color: #fff !important;
}

.dark-theme app-header .dropdown-content {
    background-color: mix($color-dark-back-primary, $color-dark-secondary, 83.5%);
}

.dark-theme app-header .dropdown-background {
  background-color: $color-dark-back-secondary;
}

.dark-theme app-header .navbar-light .navbar-toggler {
    color: rgba($color-light-secondary, .5);
    border-color: rgba($color-light-secondary, .5);
}

.dark-theme app-header .dropdown-content a:hover {
    background-color: mix($color-dark-back-primary, $color-dark-secondary, 80%);
}

.dark-theme .dropdown-item:focus, .dark-theme .dropdown-item:hover {
  background-color: mix($color-dark-back-primary, $color-dark-secondary, 80%) !important;
}

.dark-theme app-footer {
    background-color: $color-dark-back-secondary;
}

.dark-theme app-footer a {
  color: #fff;
}


.dark-theme app-balance-table .mat-row:hover {
    background-color: mix($color-dark-back-primary, $color-dark-secondary, 80%);
}

.dark-theme .announcement-unread {
    background-color: $color-dark-back-secondary;
}

.dark-theme .mat-table,
.dark-theme .mat-paginator {
    background-color: $color-dark-back-secondary !important;
}

.dark-theme {
    & app-header,
    & app-about,
    & app-home,
    & app-stablecoin,
    & app-earn {
        & .foreground-stroke {
            stroke: #000;
        }
    }
}

.dark-theme .bg-grey {
    background-color: mix($color-dark-back-primary, $color-dark-secondary, 60%);
}

.dark-theme .foreground-stroke {
    stroke: #fff;
}

.dark-theme .background-stroke {
    stroke: $color-dark-back-secondary;
}

.dark-theme .foreground-fill {
    fill: $color-dark-secondary;
}

.dark-theme .background-fill {
    fill: $color-dark-back-secondary;
}

.dark-theme .chat.expanded .message {
    background-color: $color-dark-back-secondary;
}

.dark-theme .chat.expanded .chat-display {
    background-color: $color-dark-back-secondary;
}

.dark-theme .main-chat .full-screen .message {
    background-color: $color-dark-back-secondary;
}

.dark-theme app-chat .chat.expanded .chat-input,
.dark-theme app-contact-support .chat.expanded .chat-input {
    background-color: $color-dark-back-secondary;
}

.dark-theme .main-chat .chat-input mat-icon,
.dark-theme .main-chat .chat-username mat-icon {
    background-color: $color-dark-back-primary;
}

.dark-theme main.main_,
.dark-theme main.main_coins
 {
    background-color: $color-dark-back-secondary;
}

.dark-theme main.main_coinlanding, .dark-theme main.main_about, .dark-theme main.main_czar {
  background-color: #1a1a1a;
}

.dark-theme app-home .trade-section {
    background-color: $color-dark-back-primary;
}

.dark-theme app-home .mat-row:hover {
    background-color: mix($color-dark-back-primary, $color-dark-secondary, 80%);
}

.dark-theme app-home .show-more-click:before {
    box-shadow: 10px 0px 0 $color-dark-back-primary;
}

.dark-theme app-home .show-more-click:after {
    box-shadow: -10px 0px 0 $color-dark-back-primary;
}

.dark-theme app-home .background-shade {
    background-image: linear-gradient(rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 0), rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 0.2), rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 0.4), rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 0.6), rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 0.8), rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 1));
}

.dark-theme app-home .background-shade-less {
    background-image: linear-gradient(rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 0), rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 0.2), rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 0.4), rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 0.6), rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 0.8), rgba(mix($color-dark-back-primary, $color-dark-secondary, 89.9%), 1));
}

.dark-theme input:-webkit-autofill,
.dark-theme textarea:-webkit-autofill,
.dark-theme select:-webkit-autofill {
    box-shadow: 0 0 0px 1000px $color-dark-back-secondary inset;
    -webkit-box-shadow: 0 0 0px 1000px $color-dark-back-secondary inset;
    -webkit-text-fill-color: $color-dark-secondary;
}

.dark-theme input[type=date] {
    color-scheme: dark;
}

.dark-theme app-trade-summary .summary-holder {
    background-color: mix($color-dark-back-primary, $color-dark-secondary, 86.6%);
    color: $color-dark-secondary;
}

.dark-theme .recaptcha-overlay {
    background-color: $color-dark-back-secondary;
}

.dark-theme .chat {
    background-color: $color-dark-back-secondary;
    color: $color-dark-secondary;
}

.dark-theme .chat.expanded .chat-username {
    background-color: $color-dark-back-secondary;
}

.dark-theme .emoji-panel {
    background-color: $color-dark-back-secondary;
}

.dark-theme markets-sidebar .mat-expansion-panel {
    background-color: $color-dark-back-secondary !important
}

.dark-theme .mat-expansion-panel-header[aria-disabled=true] {
    color: $color-dark-secondary !important;
}

.dark-theme app-not-found .wrapper ,
.dark-theme app-generic-response .wrapper {
    background-color: $color-dark-back-secondary;
    border-bottom: 0.3rem $color-dark-back-primary;
}

.dark-theme .mat-cell,
.dark-theme .mat-footer-cell {
    color: mix($color-dark-back-primary, $color-dark-secondary, 13.3%) !important;
}
@media (max-width: 960px) {
    .dark-theme app-transaction-history .mat-table .mat-row,
    .dark-theme app-trade-history .mat-table .mat-row {
        background-color: $color-dark-back-secondary;
    }

    .dark-theme app-transaction-history .mat-column-expandedDetail,
    cdk-column-expandedDetail,
    .dark-theme app-trade-history .mat-column-expandedDetail,
    cdk-column-expandedDetail {
        background-color: mix($color-dark-back-primary, $color-dark-secondary, 83.5%) !important;
    }
}

.dark-theme app-home .trade-section mat-card {
    background-color: $color-dark-back-primary;
    border: 1px solid $color-dark-secondary;
    box-shadow: 2px 2px 2px 0 rgba($color-dark-secondary, .07), -2px -2px 2px 0 rgba($color-dark-secondary, .07), 2px -2px 2px 0 rgba($color-dark-secondary, .07), -2px 2px 2px 0 rgba($color-dark-secondary, .07);
}

.dark-theme app-home .featured {
    background-color: $color-dark-back-primary;
}

.dark-theme app-home .featured .coin-hills span {
    color: mix($color-dark-back-primary, $color-dark-secondary, 1.9%);
}

/***/
.dark-theme main {
    background-color: #030303;
}

.dark-theme app-about .home-colours,
.dark-theme .home-colours {
  color: #000 !important;
}

.dark-theme .row.back {
    background-color: $color-dark-back-primary;
}

.dark-theme .mat-elevation-z1,
.dark-theme .mat-elevation-z2 {
    background-color: $color-dark-back-secondary;
}

.dark-theme a {
    text-decoration: underline;
    color: $color-dark-primary;
}

.dark-theme a[mat-raised-button][color=primary],
.dark-theme a[mat-raised-button][color=primary],
.dark-theme a[mat-raised-button][color=primary]:focus,
.dark-theme button[mat-raised-button][color=primary],
.dark-theme button[mat-button][color=primary],
.dark-theme .mat-step-header .mat-step-icon {
    background-color: $color-dark-primary;
    color: $color-dark-secondary;
    text-decoration: none;
}

.dark-theme app-header a[mat-raised-button][color=primary],
.dark-theme app-header a[mat-raised-button][color=primary],
.dark-theme app-header a[mat-raised-button][color=primary]:focus,
.dark-theme app-header button[mat-raised-button][color=primary],
.dark-theme app-header button[mat-button][color=primary],
.dark-theme .mat-step-header .mat-step-icon {
    background-color: #fcba36;
    color: $color-dark-secondary;
    text-decoration: none;
}

.dark-theme .mat-flat-button.mat-primary[disabled],
.dark-theme .mat-flat-button.mat-accent[disabled],
.dark-theme .mat-flat-button.mat-warn[disabled],
.dark-theme .mat-flat-button[disabled][disabled],
.dark-theme .mat-raised-button.mat-primary[disabled],
.dark-theme .mat-raised-button.mat-accent[disabled],
.dark-theme .mat-raised-button.mat-warn[disabled],
.dark-theme .mat-raised-button[disabled][disabled],
.dark-theme .mat-fab.mat-primary[disabled],
.dark-theme .mat-fab.mat-accent[disabled],
.dark-theme .mat-fab.mat-warn[disabled],
.dark-theme .mat-fab[disabled][disabled],
.dark-theme .mat-mini-fab.mat-primary[disabled],
.dark-theme .mat-mini-fab.mat-accent[disabled],
.dark-theme .mat-mini-fab.mat-warn[disabled],
.dark-theme .mat-mini-fab[disabled][disabled] {
    background-color: rgba($color-dark-secondary, 0.12) !important;
    color: rgba($color-dark-secondary, 0.3) !important;
}

.dark-theme .border-box {
    border: 1px solid $color-dark-primary;
    padding: 20px 20px 20px 20px;
    max-width: 600px;
}

.dark-theme .autotrade-border-box {
  border: 1px solid $color-dark-primary;
  padding: 10px;
  font-size: 14px;
}

.dark-theme mat-datepicker-toggle[color=primary],
.dark-theme mat-icon[color=primary] {
    color: $color-dark-primary;
    cursor: pointer;
}

.dark-theme a[mat-raised-button][color=accent],
.dark-theme a[mat-raised-button][color=accent],
.dark-theme a[mat-raised-button][color=accent]:focus,
.dark-theme button[mat-raised-button][color=accent],
.dark-theme button[mat-button][color=accent] {
    background-color: mix($color-dark-back-primary, $color-dark-secondary, 40%);
    color: $color-dark-secondary;
    text-decoration: none;
}

.dark-theme ngx-mat-drp[color=accent] .ngx-mat-drp-date-input {
    color: mix($color-dark-back-primary, $color-dark-secondary, 40%) !important;
    text-decoration: none;
}

.dark-theme a.mat-menu-item {
    text-decoration: none;
}

.dark-theme mat-progress-bar .mat-progress-bar-primary::after,
.dark-theme mat-progress-bar .mat-progress-bar-secondary::after {
    background-color: $color-dark-primary;
}

.dark-theme mat-progress-bar .mat-progress-bar-buffer {
    background-color: mix($color-dark-back-primary, $color-dark-secondary, 0.001%);
}

.dark-theme .kycmessage {
    color: $color-dark-secondary;
}

.dark-theme .negative,
.dark-theme .sell {
    color: rgb(231, 100, 100) !important;
}

.dark-theme .positive,
.dark-theme .buy {
    color: rgb(105, 204, 102) !important;
}

.dark-theme .pop-up-title {
    border-bottom: 1px solid #e2e2e2;
}

.dark-theme .hover-pop-up {
    color: $color-dark-secondary;
}

.dark-theme .center-btn-icon {
    color: $color-dark-primary;
}

.dark-theme a.link {
    color: $color-dark-primary !important;
}

.dark-theme .available-balance-box {
    border: 1px solid $color-dark-primary;
}

.dark-theme #chatWidget,
.dark-theme #formWidget {
    background-color: $color-dark-primary;
}

.dark-theme .message-icon:hover {
    color: $color-dark-primary;
}

.dark-theme .emoticon-icon:hover {
    color: $color-dark-primary;
}

.dark-theme .user {
    border: $color-dark-primary 1px solid;
}

.dark-theme .message-card .user {
    color: $color-dark-primary;
}

.dark-theme .api {
    color: $color-dark-primary;
}

.dark-theme .highlight {
    background-color: rgba($color-dark-primary, 0.8) !important;
}

.dark-theme  .info-box {
    border: 1px solid $color-dark-primary;
}

.dark-theme .clickable {
    color: $color-dark-primary;
}

.dark-theme .mat-column-sell-total,
.mat-column-sell-total-amount {
    color: $color-dark-primary;
}

.dark-theme .mat-footer-cell {
    color: $color-dark-primary;
}

.dark-theme .note-box {
    color: $color-dark-primary;
}

.dark-theme mat-row.active {
    background-color: $color-dark-primary !important;
}

.dark-theme .media-body h5 {
    color: $color-dark-primary;
}

/*.dark-theme thead:not(.mat-calendar-table-header) tr {
    background: $color-dark-primary;
}*/

.dark-theme .lvl2-notify {
    border: $color-dark-primary solid 1px;
}

.dark-theme .volume-info {
    background-color: $color-dark-primary;
}

.dark-theme .home-inputs {
  color: #fff;
}

.dark-theme app-home .mat-select-value-text {
  color: #000;
  font-weight: bold;
}

.dark-theme home-coin-landing .yellow-button {
  color: #fff;
  font-weight: bold;
}

.dark-theme app-menu-stats .txt-bold {
    color: #ffffff;
}

.dark-theme app-coin-balance .summary-total .min-w-limit {
    color: #ffffff !important;
}

.dark-theme .txt-orange {
    color: $color-dark-primary !important;
}

.dark-theme .order-types-group .mat-tab-label-active {
    background-color: $color-dark-back-secondary !important;
    opacity: 1 !important;
}

.dark-theme .order-types-group .mat-tab-label-container {
    background-color: $color-dark-back-secondary !important;
}

.dark-theme .mat-snack-bar-container {
    background:#323232;color:#fff
}

.dark-theme .border-2 {
    border: 2px solid #ef6c00;
    border-color: #ef6c00 !important;
}

.dark-theme .border-2-grey {
    border: 2px solid #808080;
}

.dark-theme markets-market-history .mat-header-cell,
.dark-theme markets-market-history .mat-paginator {
    color: rgba(255, 255, 255, 0.7) !important;
}

.dark-theme .mat-menu-panel {
    background: #333;
}

.dark-theme .mat-card {
    color: #ffffff !important;
}

@media (min-width: 1200px) {
    .dark-theme app-header .dropdown-background {
      background-color: inherit;
    }

    .dark-theme app-header a {
        color: #000 !important;
    }

    .dark-theme app-header a.active {
        color: #3a290d !important;
    }

    .dark-theme app-header a.active {
      color: #ffe8ca !important;
    }


}
