/* You can add global styles to this file, and also import other style files */
/*
body {
    max-height: 948px;
}
*/
@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'theme.scss';

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url('/fonts/OpenSans-Regular.ttf') format('TTF/OTF');
    font-display: swap;
}

/* Remove spinners from number inputs */
/* For Firefox */
input[type=number] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

::-webkit-scrollbar-corner {background-color: transparent;}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.overflowWrap {
    word-wrap: break-word;
}

.fix-gutter>.row {
    margin-left: -10px;
    margin-right: -10px;
    padding: 10px;
}

.cdk-overlay-container {
    background-color: transparent !important;
}

.cdk-global-overlay-wrapper {
    pointer-events: auto;
    overflow: auto;
}

.cdk-global-overlay-wrapper::before {
    content: '';
    display: inline-block;
    height: 100%;
    white-space: nowrap;
}

.cdk-overlay-pane {
    display: inline-block;
    text-align: left;
    white-space: normal;
}

.mat-select-value {
    overflow: visible !important;
}

/* should this be moved to /src/assets/light-theme.css */
/* START */
div.heading span.icon {
    margin-right: 15px;
    display: inline-block;
    vertical-align: top;
    height: 32px;
    width: 32px;
}

span.icon .mat-icon {
    width: 39px;
    height: 34px;
}

span.icon img {
    width: 100%;
}

div.heading span.title {
    display: inline-block;
    line-height: 13px;
    width: calc(100% - 65px);
}

span.title h4 {
    margin-bottom: 0;
}

span.title p {
    font-size: 12px;
    color: #808080;
}

label {
    font-size: 12px;
    color: #808080;
}

mat-icon[color=disabled] {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  cursor: default !important;
}

p {
    font-size: 12px;
}

.disable-text {
  font-size: 14px;
  color: #808080
}

/* END */

app-verification .mat-raised-button,
app-user-settings .mat-raised-button {
    min-width: 130px;
}

re-captcha div {
    margin: auto;
}

.cdk-overlay-pane.cdk-overlay-pane-select-search {
    width: 109px;
}

label.mat-checkbox-layout {
    white-space: normal;
}

mat-form-field {
    margin-top: 15px;
    margin-bottom: 15px;
}

.market .mat-tab-label {
    min-width: 24px;
    padding: 0px 10px 0px 10px;
    height: 25px;
}

.chart-holder .mat-tab-label {
    margin-top: 10px;
    height: 25px;
}

.sidebar-history .mat-tab-label,
.mobile-transaction-box .mat-tab-label {
    width: 100%;
    min-width: 24px;
    padding: 0px 10px 0px 10px;
    height: 25px;
}

/*.sidebar-history .mat-tab-label-content {
    padding-top: 10px;
}*/

.order .mat-tab-labels,
.sidebar-history .mat-tab-labels {
    justify-content: flex-end;
}

.order .mat-tab-labels .mat-tab-label,
.sidebar-history .mat-tab-label {
    min-width: 50px;
    padding: 0px 10px;
}

* {
    font-family: 'Open Sans', sans-serif;
}

mat-header-cell,
mat-header-cell button {
    text-align: left;
}

#chartMe iframe {
    width: 100% !important;
    height: 100% !important;
}

.clickable {
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #ef6c00;
    border-radius: 3px;
}

.full-market {
    padding-left: 0px;
    padding-right: 0px;
    overflow-y: auto;
    overflow-x: hidden;
}

.full-table {
    overflow: auto;
}

.table-heading {
    font-weight: bolder;
    font-size: 15px;
    padding-right: 12px;
    padding-bottom: 0px;
    padding-top: 10px;
    position: relative;
    top: 0px;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.toast-top-center {
    top: 0;
    right: calc(50% - 150px);
}

.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

/* toast styles */

.toast-title {
    font-weight: 700;
    font-size: 13px;
}

.toast-message {
    word-wrap: break-word;
    font-size: 11px;
}

.toast-message a,
.toast-message label {
    color: #ffffff;
}

.toast-message a:hover {
    color: #cccccc;
    text-decoration: none;
}

.toast-close-button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #ffffff;
    /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;
}

.toast-container * {
    box-sizing: border-box;
}

.toast-container .toast {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 400px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: 0 0 12px #999999;
    color: #FFFFFF;
}

.toast-container .toast:hover {
    box-shadow: 0 0 12px #000000;
    opacity: 1;
    cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
    background-image: url("data: image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
    background-color: #000000;
    opacity: 0.6;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
    background-image: url("data: image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
    background-color: #bd362f;
    opacity: 0.75;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
    background-image: url("data: image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
    background-color: #252525;
    opacity: 0.75;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
    background-image: url("data: image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
    background-color: #ff7900;
    opacity: 0.75;
}

.toast-container.toast-top-center .toast,
.toast-container.toast-bottom-center .toast {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.toast-container.toast-top-full-width .toast,
.toast-container.toast-bottom-full-width .toast {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

.toast {
    background-color: #030303;
    pointer-events: auto
}

.toast-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    background-color: #fff;
    opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
    .toast-container .toast.div {
      padding: 8px 8px 8px 50px;
      width: 11em;
    }

    .toast-container .toast-close-button {
      right: -0.2em;
      top: -0.2em;
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    .toast-container .toast.div {
        padding: 8px 8px 8px 50px;
        width: 18em;
    }

    .toast-container .toast-close-button {
        right: -0.2em;
        top: -0.2em;
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    .toast-container .toast.div {
        padding: 15px 15px 15px 50px;
        width: 25em;
    }
}

.mat-tab-label {
    font-size: 12px !important;
    /*height: 25px !important;*/
}

#market-main-table .mat-tab-label {
    font-size: 18px !important;
    padding: 0 5px 7px 2px;
    font-weight: bold;
    opacity: 1;
}

@media (min-width: 1600px) {
    .col-xlg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    .col-xlg-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .col-xlg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-xlg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xlg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }

    .col-xlg-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    .col-xlg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xlg-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }

    .col-xlg-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
    }

    .col-xlg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xlg-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    .col-xlg-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
    }

    .col-xlg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .offset-xlg-1 {
        margin-left: 8.333333%;
    }

    .offset-xlg-2 {
        margin-left: 16.666667%;
    }

    .offset-xlg-3 {
        margin-left: 25%;
    }

    .offset-xlg-4 {
        margin-left: 33.333333%;
    }

    .offset-xlg-5 {
        margin-left: 41.666667%;
    }

    .offset-xlg-6 {
        margin-left: 50%;
    }

    .offset-xlg-7 {
        margin-left: 58.333333%;
    }

    .offset-xlg-8 {
        margin-left: 66.666667%;
    }

    .offset-xlg-9 {
        margin-left: 75%;
    }

    .offset-xlg-10 {
        margin-left: 83.333333%;
    }

    .offset-xlg-11 {
        margin-left: 91.666667%;
    }

    .d-xlg-0 {
        display: none !important;
    }

    .d-xlg-block {
        display: block !important;
    }

    .text-xlg-left {
        text-align: left!important;
    }

    .text-xlg-center {
        text-align: center!important;
    }

    .text-xlg-right {
        text-align: right!important;
    }
}

.disable-control {
    pointer-events: none;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

div.order-form .field-label figure {
    justify-content: left;
}

.mobile-transaction-box .mat-tab-body-content {
    overflow-x: hidden;
}

.interval-selection .mat-tab-label {
    min-width: 100px;
}

@media(min-width: 0px) {
    .full-market {
        display: none;
    }

    .mat-dialog-container {
        padding: 24px 0px 24px 0px !important;
    }

    .cdk-overlay-pane {
        max-width: 90% !important;
    }
}

@media(min-width: 768px) {
    .full-market {
        display: block;
    }

    .mat-dialog-container {
        padding: 24px !important;
    }

    .cdk-overlay-pane {
        max-width: 80vw !important;
    }
}


@media(max-width: 991px) {
  .trade-height-limit mat-table {
    max-height: 265px;
  }
}

@media(min-width: 992px) {
  .trade-height-limit mat-table {
    height: 310px;
  }
}

@media(min-width: 1199px) {
  .trade-height-limit mat-table {
    height: 290px;
  }
}

@media(min-width: 1601px) {
  .trade-height-limit mat-table {
    height: 300px;
  }
}

@media(min-width: 1764px) {
  .trade-height-limit mat-table {
    height: 296px;
  }
}

@media(min-width: 2100px) {
  .trade-height-limit mat-table {
    height: 300px;
  }
}

@media(min-width: 992px) {
    .homepage-trade-section .mat-tab-label-active {
        font-size: 1.75rem !important;
    }
}

.mat-tab-header-pagination {
    z-index: 1 !important;
}

.about-announcements {
  position: relative;
  top: 25px;
  width: max-content;
  min-width: 100px;
  max-width: 275px;
}

.announcements {
    position: absolute;
    top: 5px;
    right: 8px;
    width: max-content;
    min-width: 100px;
    max-width: 275px;
}

.trading-summary {
    position: absolute;
    right: 0px;
    width: 350px;
    max-width: 350px !important;
    font-size: 12px;
    margin-right: -15px;
}

.main-label .mat-tab-label-content {
    font-size: 16px !important;
    font-weight: bolder !important;
}

app-sidemarket div.ActiveMarket {
    background-color: #ff7900;
    color: white;
}

.mat-form-field-infix {
    padding-bottom: 2px !important;
}

.mat-input-element {
    line-height: 1.3 !important;
}

.mt-2px {
    margin-top: 2px !important;
}

markets-sidebar mat-expansion-panel .mat-expansion-panel-body {
    padding: 0 !important;
}

markets-sidebar mat-expansion-panel-header {
    background: inherit !important;
}

markets-sidebar mat-expansion-panel {
    box-shadow: none !important;
}

markets-sidebar mat-expansion-panel-header {
    padding: 0 !important;
}

@media(max-width: 991px) {
    .trade-height-limit mat-table {
        max-height: 265px;
        margin-bottom: 1.5rem;
    }

    .market-height-limit mat-table {
        max-height: 214px;
        margin-bottom: 0.7rem;
    }

    markets-orderbook .buy-section {
        margin-top: .25rem!important;
    }
}

@media(min-width: 992px) {
    //.trade-height-limit mat-table {
    //    height: 370px;
    //}
    //
    .market-height-limit mat-table {
        max-height: 325px;
    }
}

@media(min-width: 1199px) {
    //.trade-height-limit mat-table {
    //    height: 314px;
    //}
    //
    .market-height-limit mat-table {
        max-height: 282px;
    }
}

@media(min-width: 1764px) {
    //.trade-height-limit mat-table {
    //    height: 300px;
    //}
    //
    .market-height-limit mat-table {
        max-height: 268px;
    }
}

app-home .trade-section ul li mat-icon svg {
    margin-bottom: 2px;
}

markets-orderform .table-heading,
app-order-types .table-heading {
    padding-top: 0;
}

markets-orderform .mat-form-field-appearance-legacy .mat-form-field-wrapper,
app-order-types .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
}

markets-orderform .mat-form-field-appearance-legacy .mat-form-field-underline,
app-order-types .mat-form-field-appearance-legacy .mat-form-field-underline {
    bottom: 0;
}

markets-orderform .mat-form-field-appearance-legacy .mat-form-field-infix,
app-order-types .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0 0 0.4375em 0;
}

app-spectrum-order-chainex div.order-form .field-label figure,
markets-orderform div.order-form .field-label figure,
app-order-types div.orderForm .field-label figure,
div.order-form .field-label figure {
    padding-top: 7px;
}

app-spectrum-order-chainex .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper,
markets-orderform .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper,
app-order-types .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
    margin-top: 15px;
}

app-spectrum-order-chainex .mat-grid-tile,
markets-orderform .mat-grid-tile,
app-order-types .mat-grid-tile {
    overflow: visible;
}

app-spectrum-order-chainex .mat-error,
markets-orderform .mat-error,
app-order-types .mat-error {
    float: right;
}

button.mat-sort-header-button:focus,
button[type=button]:focus,
[type=button].mat-sort-header-button:focus {
    outline: 0 !important;
}

.dialog-over-flow {
    max-height: 80vh;
    -webkit-overflow-scrolling: touch;
}

.order-types-group .mat-tab-label {
    min-width: 24px;
    padding: 0px 10px 0px 10px;
    height: 25px;
}

/*.order-types-group .mat-tab-label {
    min-width: 24px;
    padding: 0px 10px 0px 10px;
    height: 25px;
}*/

.order-types-group>mat-tab-header {
    margin-bottom: -5px;
    padding: 1px 0px 0px 0px;
}

/*.order-types-group mat-ink-bar {
    display: none;
}

}*/

.order-types-group .mat-elevation-z2 {
    min-height: 261px;
}

.btn-long {
    padding-left: 50px;
    padding-right: 50px;
}

.btn-w {
  min-width: 200px;
}

.form-header {
    background: #6d6f71;
}

.form-header h1,
.form-header h2,
.form-header p,
.form-body h5 {
    font-weight: 300;
}

.form-body label,
.form-body small,
.form-body p {
    color: #7c8188;
}

.text-underline {
    text-decoration: underline;
}

a:hover,
a:focus {
    text-decoration: none;
    outline: 0;
}

.tab .nav-tabs {
    border: 0;
    /* border-bottom: 2px solid #079fc9; */
    margin: 0;
    margin-top: 25px;
    padding-bottom: 7px;
}

.tab .nav-tabs li a {
    padding: 10px 20px;
    margin: 0 10px -1px 0;
    font-size: 17px;
    font-weight: 600;
    color: #787d86 !important;
    border: 1px solid #7c8188;
    border-bottom: 0;
    z-index: 1;
    position: relative;
    transition: all 0.3s ease 0s;
}

.tab .nav-tabs li a:hover,
.tab .nav-tabs li.active a {
    background: #fff;
    border-top: 4px solid #ff7900;
    border-bottom-color: transparent;
}

.tab .nav-tabs li a:before {
    content: '';
    display: block;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

.tab .nav-tabs li.active a:before,
.tab .nav-tabs li a:hover:before {
    transform: scaleX(1);
}

.tab .tab-content {
    padding: 10px;
    font-size: 17px;
    color: #6f6f6f;
    line-height: 30px;
    letter-spacing: 1px;
    position: relative;
    border: 1px solid #7c8188;
}

@media only screen and (max-width: 479px) {
    .tab .nav-tabs {
        border: 0;
    }

    .tab .nav-tabs li {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }

    .tab .nav-tabs li a {
        margin: 0;
        border-bottom: 2px solid transparent;
    }

    .tab .nav-tabs li a:before {
        content: '';
        width: 100%;
        height: 2px;
        background: #079fc9;
        position: absolute;
        bottom: -2px;
        left: 0;
    }
}

.short-break {
    border-top: 4px solid #ff7900;
    max-width: 50px;
    margin-top: 10px;
    margin-bottom: 20px;
    align-self: left;
}

mat-dialog-container {
    position: relative;
}

.dialog-close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
}

/*Kill stepper numbering*/
mat-step-header .ng-star-inserted {
    display: none;
}

.mat-icon-button {
    outline: 0 !important;
}

.mat-stepper-horizontal-line {
    display: none;
}

.mat-step-header {
    margin: 1rem 0.33rem 1rem !important;
    width: 18px !important;
}

.mat-step-icon, .mat-step-header-ripple {
    width: 18px !important;
    height: 18px !important;
}

.mat-horizontal-stepper-header {
    display: block;
    pointer-events: none !important;
    width: 24px !important;
    height: 22px !important;
    padding: 0 !important;
    background-color: transparent !important;
}

.mat-horizontal-stepper-header-container {
    margin: auto;
    width: min-content;
}

.text-right {
    justify-content: flex-end !important;
}

.custom-label-size .mat-tab-label {
    height: 43px;
    max-width: 60px;
    min-width: 55px;
    font-weight: bolder;
    font-size: 1rem !important;
}

.custom-label-size .mat-tab-label-active {
    color: #ef6c00;
    font-weight: bolder;
}

.custom-label-size .mat-tab-label-content {
    position: relative;
    bottom: -9px;
    font-weight: bolder;
}

.custom-label-size {
    font-weight: bolder;
}

/*.customHomeFilter .mat-tab-header {
    border-bottom: 0;
}

.customHomeFilter .mat-form-field-underline {
    display: none;
}*/

.recaptcha-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1000;
    bottom: 0px;
    right: 0px;
    display: none;
}

.no-decoration {
    text-decoration: none !important;
}

.no-overflow {
    overflow: hidden;
}

.no-overflow mat-table {
    overflow: hidden !important;
}

.order-indicator {
    color: #ff7900;
}

.fee-info, .stop-info, .verification-info, .referral-info {
    color: #ff7900;
    cursor: pointer;
}

@media screen and (min-width : 991px){
  html,
  body {
    height: 100vh;
  }
}

.p-relative {
    position: relative !important;
}

.chainex-box {
    padding-left: 20px;
    padding-right: 20px;
}

.cx-mt-1 {
    margin-top: .25rem !important;
}

.cx-pl-10px {
    padding-left: 10px !important;
}

.cx-pr-10px {
    padding-right: 10px !important;
}

.cx-pl-1 {
    padding-left: .25rem !important;
}

.cx-pr-1 {
    padding-right: .25rem !important;
}

@media (min-width: 992px) {
    app-header a.active div.dec {
        background-color: #fd7923;
    }
}

.no-z {
    z-index: 0 !important;
}

.color_green {
    color: #69cc66 !important;
}

.dark-box {
    color: #fff !important;
}

.dark-signup-box {
  border: 1px solid #363337;
  background-color:#1a1a1a;
  height: 100%;
}


.dark-input,
.dark-theme .themed-input {
  background-color: #2a2a2a;
  border-color: #7e7e80;
  border-style: solid;
  color: #808082;
}

.text-bold {
  font-weight: bold;
}

.light-box {
  color: #000 !important;
}

.light-input,
.light-theme .themed-input {
  background-color: #fefefe;
  border-color: #d4d3d3;
  border-style: solid;
}

.orange-text {
  color: #ef6c00 !important;
}

.suspended-text {
  background-color: #FFC1BB !important;
  color: #ff6433 !important;
}

.orange-header {
    color: #ef6c00;
    font-size: 1.85;
    font-weight: bold;
}

.orange-gradient {
  background-image: linear-gradient(to right, #fcba36, #ef6c00);
}

.signup-box {
    border: 1px solid #e6e6e6;
    background-color: #fefefe;
    height: 100%;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
}

.stats-shadow {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
  animation: float 6s ease-in-out infinite;
}

.dark-stats-shadow {
  background-color: #1a1a1a;
  box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.2);
  animation: float 6s ease-in-out infinite;
}

.grey-text {
  color: #808080;
}

.white-text {
  color: #fff !important;
}

.disabled-button {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    cursor: default !important;
  }

.yellow-button {
  background-color: #fcba36 !important;
}

.yellow-text {
    color: #fcba36;
}

mat-icon.order-indicator {
    width: 14px !important;
    height: auto !important;
    position: relative;
}

mat-icon.fee-info, mat-icon.stop-info, mat-icon.verification-info {
    width: 18px !important;
    height: auto !important;
    position: relative;
}

.fee-info-tooltip, .stop-info-tooltip, .verification-info-tooltip {
    white-space: pre-line;
}

app-home .mat-row:hover,
app-home .mat-row:hover .mat-cell,
app-home .mat-row:hover .mat-cell a {
    background-color: #ef6c00 !important;
}

app-home .main-links a:hover,
app-home .trade-section .header {
    color: #ef6c00;
}

markets-sidebar span.mat-expansion-indicator {
    margin-right: 10px;
}

.mat-card li {
    list-style: none;
}

.flex-end {
    justify-content: flex-end;
    padding-right: 15px;
    padding-left: 15px;
    flex-wrap: inherit !important;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .flex-end {
        justify-content: flex-start;
    }
}

.blueprint-background {
    background-image: url('/assets/chainex/images/pages/home/light-mode/blueprint_background.svg');
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 73vh;
    height: 100%;
}

.primary-bg {
  background-color: rgba(0, 0, 0, 0.05);
}



.dark-primary-bg {
background-color: rgba(255, 255, 255, 0.13);
}

.blueprint-background-flipped {
  background-image: url('../../assets/chainex/images/pages/home/light-mode/blueprint_background_flipped.svg');
  background-size: cover;
  background-position-y: center;
  background-position-x: right;
  width: 100%;
  background-repeat: no-repeat;
}

.map-background {
  background-image: url('/assets/' + $exchange-name + '/images/pages/home/light-mode/map_background.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.transparent-background .mat-dialog-container {
  height: 100vh  !important;
  width: 100vw  !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.vertical-scroll {
    overflow-x: scroll;
}
