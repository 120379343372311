$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
@import '~material-design-icons-iconfont/src/material-design-icons.scss';

$FontPathOpenSans: '~open-sans-fonts/open-sans';
@import '~open-sans-fonts/_open-sans-regular.scss';

@import '~@angular/material/theming';


$general-typography: mat-typography-config(
    $font-family: '"Open Sans", arial, sans-serif',
    $headline:      mat-typography-level(36px, 42px, 1000),
    $title:         mat-typography-level(14px, 14px, 600),
    $subheading-2:      mat-typography-level(24px, 32px, 600),
    $subheading-1:      mat-typography-level(18px, 30px, 600),
    $body-1:        mat-typography-level(14px, 20px, 400)
);

@include mat-core($general-typography);

$exchange-name: 'chainex';

$color-light-primary: #ff7900;/*#ff7900;*/
$color-light-secondary: #000000;/*#000000;*/

$color-light-back-primary: #ffffff;/*#ffffff;*/
$color-light-back-secondary: #000000;/*#000000;*/


$color-dark-primary: #ff7900;/*#ff7900;*/
$color-dark-secondary: #ffffff;/*#ffffff;*/

$color-dark-back-primary: #030303; /*#000000;*/
$color-dark-back-secondary: #1a1a1a; /*#1a1a1a;*/

$custom-primary: mat-palette($mat-orange, 800);
$custom-accent:  mat-palette($mat-grey, 400);

$custom-theme: (
    primary: $custom-primary,
    accent: $custom-accent,
    warn: mat-palette($mat-red),
    is-dark: false,
    foreground:  (
        base:              #211d2d,
        divider:           $dark-dividers,
        dividers:          $dark-dividers,
        disabled:          $dark-disabled-text,
        disabled-button:   rgba(#000000, .26),
        disabled-text:     $dark-disabled-text,
        hint-text:         $dark-disabled-text,
        secondary-text:    $dark-secondary-text,
        icon:              rgba(#000000, .54),
        icons:             rgba(#000000, .54),
        text:              #211d2d,
        slider-min:        rgba(#000000, .87),
        slider-off:        rgba(#000000, .26),
        slider-off-active: rgba(#000000, .38),
    ),
    background: $mat-light-theme-background,
);

//mat-light-theme($custom-primary, $custom-accent);

@include angular-material-theme($custom-theme);

$alt-theme: (
    primary: $custom-primary,
    accent: $custom-accent,
    warn: mat-palette($mat-red),
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: (
        status-bar: #000000,
        app-bar:    #1a1a1a,
        background: #303030,
        hover:      rgba(#ffffff, .04), // TODO(kara): check style with Material Design UX
        card:        #333333,
        dialog:      #1a1a1a,
        disabled-button: rgba(#fff, .12),
        raised-button:  #1a1a1a,
        focused-button: $light-focused,
        selected-button: map_get($mat-grey, 900),
        selected-disabled-button:  #1a1a1a,
        disabled-button-toggle: #000,
        unselected-chip: map_get($mat-grey, 700),
        disabled-list-option: #000,
    ),
);

.dark-theme {
    @include angular-material-theme($alt-theme);
}


/*
@function mat-typography-config(
  $font-family:   'Roboto, "Helvetica Neue", sans-serif',
  $display-4:     mat-typography-level(112px, 112px, 300),
  $display-3:     mat-typography-level(56px, 56px, 400),
  $display-2:     mat-typography-level(45px, 48px, 400),
  $display-1:     mat-typography-level(34px, 40px, 400),
  $headline:      mat-typography-level(24px, 32px, 400),
  $title:         mat-typography-level(20px, 32px, 500),
  $subheading-2:  mat-typography-level(16px, 28px, 400),
  $subheading-1:  mat-typography-level(15px, 24px, 400),
  $body-2:        mat-typography-level(14px, 24px, 500),
  $body-1:        mat-typography-level(14px, 20px, 400),
  $caption:       mat-typography-level(12px, 20px, 400),
  $button:        mat-typography-level(14px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input:         mat-typography-level(16px, 1.125, 400)
)
*/

::ng-deep .light-theme .setBackgroundColor {
  background: $color-light-back-primary !important;
}

::ng-deep .dark-theme .setBackgroundColor {
  background: $color-dark-back-secondary !important;
}

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #ff7900!important;
}

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #ff7900 !important;
}
// Center of radio button
::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #ff7900 !important;
}
// Border (ring around the dot)
::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff7900 !important;
}
